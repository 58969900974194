import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    /* mode: 'dark',
    primary: {
      main: '#463750',
    },
    secondary: {
      main: '#f44f1f'
    } */
  },
});
